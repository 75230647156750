import React from 'react';
import styled from 'styled-components';
// import ListItemContainer from '../../components/ThankyouPages/ListItemContainer';
// import AdButler from '../../components/ThankyouPages/AdButler';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const IntroBlock = styled.div`
  box-sizing: border-box;
  background: #3f64ae;
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  max-width: 980px;
  h1 {
    font-size: 28px;
    font-weight: 300;
    margin: 0;
    @media (max-width: 801px) {
      font-size: 15px;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 400;
  }
  .ybm {
    font-size: 44px;
    font-weight: 500;
    @media (max-width: 801px) {
      font-size: 26px;
    }
  }
`;
const EfmContainer = styled.div`
  font-family: 'Roboto', Arial, san-serif;
  width: calc(100% - 20px);
  max-width: 1170px;
  margin: auto;
  img {
    margin: 0;
  }
  ul {
    display: block;
    margin: 0;
    padding: 0;
  }
  section {
    box-sizing: border-box;
    width: calc(100vw - 40px);
    max-width: 700px;
    padding: 10px 20px 10px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 3px 8px #555;
    margin-bottom: 10px;
    @media (max-width: 801px) {
      padding: 10px;
    }
  }
  .contactInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 980px;
    margin: auto;
    .phoneNumber {
      background: #18569d;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-top: 10px;
      a {
        color: #fff;
      }
      @media (max-width: 801px) {
        width: 100%;
        text-align: center;
      }
    }
    .clickApply {
      height: 49px;
      background: #18569d;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      @media (max-width: 801px) {
        width: 100%;
        text-align: center;
      }
    }
    @media (max-width: 801px) {
      flex-wrap: wrap;
    }
  }
  .logoContainer {
    width: calc(100% - 20px);
    margin: 20px auto;
    max-width: 500px;
  }

  .questions {
    box-sizing: border-box;
    background: #dfe7ee;
    border: none;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    max-width: 980px;
    color: #0f2030;
    h3 {
      font-size: 14px;
      text-align: center;
    }
    .nmlsBlock {
      padding-top: 20px;
    }
    .nmls {
      font-size: 11px;
      text-align: center;
      padding-bottom: 0;
    }
    .threeItemBlock {
      margin-top: 20px;
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      .contentBlock {
        width: 31%;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        p {
          font-size: 13px;
          font-weight: 600;
          width: 100%;
          margin-top: 5px;
          margin-bottom: 14px;
        }
        .iconContainer {
          width: 65px;
        }
        @media (max-width: 801px) {
          width: 100%;
        }
      }
      @media (max-width: 801px) {
        flex-wrap: wrap;
      }
    }
    .dividerContainer {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(to bottom, #c4bf9d 0%, #99aed3 100%);
      color: #fff;
      padding: 8px;
      border-radius: 10px;
      margin-bottom: 10px;
      text-align: center;
      h2 {
        font-family: 'Roboto', Arial, san-serif;
        font-weight: 400;
        text-shadow: 0 3px 6px #aaa;
        margin: 0;
        margin-left: 30px;
        font-size: 30px;
        padding-bottom: 25px;
        @media (max-width: 801px) {
          font-size: 15px;
        }
      }
    }
    .questionList {
      ul {
        display: block;
        column-count: 2;
        column-gap: 20px;
        font-size: 14px;
        font-weight: 600;
        padding-left: 5px;
        @media (max-width: 801px) {
          column-count: 1;
        }
        li {
          list-style-type: none;
          margin-bottom: 10px;
          background: url('https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooDisc.png')
            0 4px/12px no-repeat;
          padding-left: 20px;
        }
      }
    }
    .disclaimer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 10px;
        margin-left: 20px;
      }
      a {
        color: #2863a2;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: 801px) {
    width: 100%;
    .ameriSave .no-bs .container .contactInfo {
      flex-wrap: wrap;
    }
  }
`;

const EfmThankyou = () => (
  <>
    <EfmContainer>
      <IntroBlock>
        <h1>
          <span className="ybm">You’ve been matched</span>
          <br />
          with our featured provider, FREEDOM MORTGAGE
        </h1>
      </IntroBlock>
      <div className="logoContainer">
        <img
          src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/FM_Logo.png"
          alt="Freedom Mortgage"
        />
      </div>
      <section className="questions">
        <h3>
          Freedom Mortgage is proud to be the #1 VA lender<sup>1</sup> in the
          United States. For more than 25 years, Freedom Mortgage has helped
          veterans and active military personnel to achieve the American dream
          of homeownership. Here's what you need to know
        </h3>
        <div className="threeItemBlock">
          <div className="contentBlock">
            <div className="iconContainer">
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooHomeIcon.png"
                alt="Home Icon"
              />
            </div>
            <p>
              A VA loan specialist will call you right away and give you an
              interest rate quote based on your personal needs and
              circumstances.
            </p>
          </div>
          <div className="contentBlock">
            <div className="iconContainer">
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooInstitutionIcon.png"
                alt="Institution Icon"
              />
            </div>
            <p>
              Freedom Mortgage is dedicated to helping military personnel
              refinance their homes with low rates, no hidden fees, and flexible
              credit terms.
            </p>
          </div>
          <div className="contentBlock">
            <div className="iconContainer">
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooMoneyIcon.png"
                alt="Money Icon"
              />
            </div>
            <p>
              Our VA loan specialists will make refinancing your home fast and
              easy, and they’ll work with you every step along the way.
            </p>
          </div>
        </div>
        <div className="dividerContainer">
          <div>
            <h2>About VA streamline refinancing (IRRRL)</h2>
            <p>
              Homeowners with VA loans may qualify for VA streamline
              refinancing, also known as the Interest Rate Reduction Refinance
              Loan ("IRRRL") program. Benefits include:
            </p>
          </div>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooQuestionIcon.png"
            alt="Question Icon"
          />
        </div>
        <div className="questionList">
          <ul>
            <li>
              Lower rates. Streamline refinancing helps you take advantage of
              lower rates.
            </li>
            <li>
              Better terms. You can switch from an adjustable-rate to a
              fixed-rate mortgage with an IRRRL, and change other terms too.
            </li>
            <li>
              No home appraisal. In most cases, you do not need a new appraisal
              to estimate the current value of your home.
            </li>
            <li>
              No income verification. You won’t have to send documents that show
              your current income.
            </li>
            <li>
              Faster closings. Our VA loan specialists will help you refinance
              your home quickly and easily.
            </li>
          </ul>
        </div>
        <div className="nmlsBlock">
          <p className="nmls">
            Mortgage loans are offered through Freedom Mortgage Corporation, 907
            Pleasant Valley Avenue, Mt. Laurel,, NJ 08054. Lender NMLS ID:
            <a
              href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/2767"
              target="_blank"
              rel="noopener noreferrer"
            >
              2767 (www.nmlsconsumeraccess.org).
            </a>{' '}
          </p>
          <p className="nmls">1.Inside Mortgage Finance, 1Q2020</p>
        </div>
      </section>
    </EfmContainer>
  </>
);

export default withThankyouWrapper(EfmThankyou);
